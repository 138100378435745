import React, { useState } from 'react';

import {
  Button,
  Group,
  LoadingOverlay,
  Paper,
  Stack,
  Textarea,
  TextInput,
  Title,
  useMantineTheme,
  Checkbox,
  Box,
  Text,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useShallowEffect } from '@mantine/hooks';
import { IconDots } from '@tabler/icons-react';
import StateAutoComplete from 'components/form-fields/auto-complete-fields/StateAutoComplete';
import PhoneInput from 'components/form-fields/input-fields/PhoneInput';
import PostalCodeInput from 'components/form-fields/input-fields/PostalCodeInput';
import CanadaProvinceTerritorySelect from 'components/form-fields/select-fields/CanadaProvinceTerritorySelect';
import CountrySelect from 'components/form-fields/select-fields/CountrySelect';
import GenderSelect from 'components/form-fields/select-fields/GenderSelect';
import HelpButton from 'components/HelpButton';
import dayjs from 'dayjs';
import useSelfStaff from 'hooks/staff/useSelfStaff';
import useUpdateSelfStaff from 'hooks/staff/useUpdateSelfStaff';
import { emailValidator, nameValidator, phoneValidator } from 'utils/form-validators';
import { formatPhone } from 'utils/formatters';
import notificationHandler from 'utils/notifications';

interface StaffAccountFormValues {
  staffId: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  dob: Date | null;
  isCoach: boolean;
  gender:
    | 'Male'
    | 'Female'
    | 'Transgender'
    | 'Non-binary/Non-conforming'
    | 'Prefer not to respond'
    | ''
    | null;
  address: {
    addressLines: string | null;
    locality: string | null;
    region: string | null;
    zipCode: string | null;
    country: 'CA' | 'US';
  };
  emergencyName: string | null;
  emergencyPhone: string;
  medicalNotes: string | null;
}

interface IStaffAccountForm {
  canHide?: boolean;
}

const StaffAccountForm = ({ canHide = true }: IStaffAccountForm): JSX.Element => {
  const theme = useMantineTheme();

  const [isEditable, setIsEditable] = useState(false);

  const { successNotification, errorNotification } = notificationHandler();
  const updateMutation = useUpdateSelfStaff();

  const { data, isLoading, isSuccess } = useSelfStaff();

  const form = useForm<StaffAccountFormValues>({
    initialValues: {
      staffId: data?.data.staffId || '',
      email: data?.data.email || '',
      firstName: data?.data.firstName || '',
      lastName: data?.data.lastName || '',
      phone: data?.data.phone || '',
      dob: data?.data.dob ? dayjs(data?.data.dob).toDate() : null,
      isCoach: data?.data.isCoach || false,
      gender: data?.data.gender || null,
      address: {
        addressLines: data?.data.address?.addressLines || '',
        locality: data?.data.address?.locality || '',
        region: data?.data.address?.region || '',
        zipCode: data?.data.address?.zipCode || '',
        country: data?.data.address?.country || 'US',
      },
      emergencyName: data?.data.emergencyName || '',
      emergencyPhone: data?.data.emergencyPhone || '',
      medicalNotes: data?.data.medicalNotes || '',
    },

    validate: {
      firstName: value => nameValidator(value),
      lastName: value => nameValidator(value),
      email: value => emailValidator(value),
      phone: value => (value ? phoneValidator(value) : null),
      emergencyPhone: value => (value ? phoneValidator(value) : null),
    },
    transformValues: values => ({
      ...values,
      phone: formatPhone(values.phone),
      emergencyPhone: formatPhone(values.emergencyPhone),
      address: {
        ...values.address,
        zipCode: values.address.zipCode?.toUpperCase() || null,
      },
    }),
  });

  const handleSetForm = (): void => {
    if (isSuccess && data?.data) {
      form.setValues({
        ...data.data,
        dob: data.data.dob ? dayjs(data.data.dob).toDate() : null,
        phone: data.data.phone ?? '',
        emergencyPhone: data.data.emergencyPhone ?? '',
      });
      form.resetDirty();
    }
  };

  const handleFormReset = (): void => {
    handleSetForm();
    setIsEditable(false);
  };

  const handleSubmit = (values: StaffAccountFormValues): void => {
    if (isSuccess && data?.data) {
      updateMutation.mutate(
        {
          ...data.data,
          ...values,
          dob: values.dob ? dayjs(values.dob).format('YYYY-MM-DD') : null,
          phone: values.phone.length > 10 ? values.phone : '',
          gender: values.gender ?? null,
          emergencyName: values.emergencyName ?? null,
          emergencyPhone: values.emergencyPhone.length > 10 ? values.emergencyPhone : '',
          medicalNotes: values.medicalNotes ?? null,
        },
        {
          onSuccess: () => {
            successNotification('Account details updated successfully');
            handleFormReset();
          },
          onError: error => {
            errorNotification('Account details update failed', error.response?.data.message);
          },
        },
      );
    }
  };

  useShallowEffect(() => {
    handleSetForm();
  }, [data?.data, isSuccess]);

  return (
    <Paper radius='lg' p='md' shadow='md' withBorder>
      <Group position='apart' pb='xs'>
        <Title order={4}>General Information</Title>
        <Group position='right'>
          {isEditable ? (
            <>
              <Button
                variant='light'
                compact
                color='red'
                onClick={handleFormReset}
                disabled={updateMutation.isLoading}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                form='staff-account-form'
                compact
                color='green'
                loading={updateMutation.isLoading}
              >
                Save
              </Button>
            </>
          ) : (
            <Button compact onClick={() => setIsEditable(true)}>
              Edit
            </Button>
          )}
        </Group>
      </Group>
      <form id='staff-account-form' onSubmit={form.onSubmit(handleSubmit)}>
        <LoadingOverlay visible={isLoading} />
        <Stack spacing='xs' p='xs'>
          <Group grow spacing='xs'>
            <TextInput
              {...form.getInputProps('firstName')}
              label='First Name'
              placeholder='Enter First Name'
              required={isEditable}
              disabled={!isEditable}
            />
            <TextInput
              {...form.getInputProps('lastName')}
              label='Last Name'
              placeholder='Enter Last Name'
              required={isEditable}
              disabled={!isEditable}
            />
          </Group>
          <Group>
            <TextInput
              {...form.getInputProps('email')}
              type='email'
              label='Email / Login'
              placeholder='Enter Email'
              disabled
              sx={{ flexGrow: 1 }}
            />
            {isEditable && (
              <Box pt='20px'>
                <HelpButton width={'md'} position='top-start' withinPortal>
                  <Text size='sm' sx={{ whiteSpace: 'normal' }}>
                    {`To change emails use the `}
                    <Text fw={600} span>
                      Edit Login
                    </Text>
                    {` button in your profile selection page.`}
                  </Text>
                </HelpButton>
              </Box>
            )}
          </Group>
          {canHide && !isEditable ? (
            <Group position='center'>
              <IconDots color={theme.colors.gray[5]} />
            </Group>
          ) : (
            <>
              <Group grow spacing='xs'>
                <PhoneInput
                  {...form.getInputProps('phone')}
                  defaultCountry={form.values.address.country.toLowerCase()}
                  label='Phone'
                  placeholder='Enter Phone Number'
                  disabled={!isEditable}
                />
                <DateInput
                  {...form.getInputProps('dob')}
                  label='Date of Birth'
                  placeholder='MM/DD/YYYY'
                  valueFormat='MM/DD/YYYY'
                  clearable
                  defaultLevel='decade'
                  disabled={!isEditable}
                  maxDate={dayjs().toDate()}
                  firstDayOfWeek={0}
                />
              </Group>
              <Group grow>
                <GenderSelect {...form.getInputProps('gender')} disabled={!isEditable} />
                {isSuccess && data?.data.isOwner && (
                  <Checkbox
                    {...form.getInputProps('isCoach')}
                    label='Coach'
                    checked={form.getInputProps('isCoach').value ?? false}
                    style={{ paddingTop: 20, paddingLeft: 20 }}
                    disabled={!isEditable}
                  />
                )}
              </Group>
              <TextInput
                {...form.getInputProps('address.addressLines')}
                type='text'
                label='Address'
                placeholder='Enter Address'
                disabled={!isEditable}
              />
              <Group grow align='top'>
                <TextInput
                  {...form.getInputProps('address.locality')}
                  type='text'
                  label='City'
                  placeholder='Enter City'
                  disabled={!isEditable}
                />
                {form.values.address.country == 'US' && (
                  <StateAutoComplete
                    {...form.getInputProps('address.region')}
                    clearable
                    disabled={!isEditable}
                  />
                )}
                {form.values.address.country == 'CA' && (
                  <CanadaProvinceTerritorySelect
                    {...form.getInputProps('address.region')}
                    clearable
                    disabled={!isEditable}
                  />
                )}
              </Group>
              <Group grow align='top'>
                <PostalCodeInput
                  {...form.getInputProps('address.zipCode')}
                  country={form.values.address.country}
                  disabled={!isEditable}
                  formErrors={form.errors.postalCode}
                />
                <CountrySelect
                  label='Country'
                  value={form.values.address.country}
                  onChange={value => {
                    form.setFieldValue('address.region', '');
                    form.setFieldValue('address.zipCode', '');
                    form.setFieldValue('address.country', value);
                  }}
                  disabled={!isEditable}
                />
              </Group>
              <TextInput
                {...form.getInputProps('emergencyName')}
                type='text'
                label='Emergency Contact'
                placeholder='Enter Full Name'
                disabled={!isEditable}
              />
              <PhoneInput
                {...form.getInputProps('emergencyPhone')}
                label='Emergency Phone'
                onChange={value => form.setFieldValue('emergencyPhone', value)}
                placeholder='Enter Phone Number'
                disabled={!isEditable}
              />
              <Textarea
                {...form.getInputProps('medicalNotes')}
                label='Medical Notes'
                placeholder='Enter Medical Notes'
                disabled={!isEditable}
              />
            </>
          )}
        </Stack>
      </form>
    </Paper>
  );
};

export default StaffAccountForm;
