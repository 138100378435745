import { AxiosResponse } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { Session } from 'types';
import { client } from 'utils/api-client';

export function getSession(): Promise<AxiosResponse<Session>> {
  return client.get('/auth/session/');
}

export default function useSession(): UseQueryResult<{ data: Session }> {
  return useQuery(['session'], getSession);
}
