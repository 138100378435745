import React from 'react';

import { ActionIcon, Popover, PopoverProps, MantineSize } from '@mantine/core';
import { IconHelpSquare } from '@tabler/icons-react';

const HelpButton = ({ children, ...props }: PopoverProps & { size?: MantineSize }): JSX.Element => {
  return (
    <Popover shadow='lg' radius='md' withArrow {...props}>
      <Popover.Target>
        <ActionIcon variant='subtle' color='kiloBlue'>
          <IconHelpSquare />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>{children}</Popover.Dropdown>
    </Popover>
  );
};

export default HelpButton;
