import { AxiosResponse } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { client } from 'utils/api-client';

export function getCsrf(): Promise<AxiosResponse<null>> {
  return client.get('/auth/csrf/');
}

export default function useCsrf(): UseQueryResult<null> {
  return useQuery(['csrf'], getCsrf);
}
