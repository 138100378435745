import React, { useMemo } from 'react';

import {
  Anchor,
  Badge,
  Button,
  createStyles,
  Divider,
  Flex,
  Group,
  Paper,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { IconClockFilled, IconMapPinFilled, IconUser, IconUsers } from '@tabler/icons-react';
import { AthleteAutoCompleteValue } from 'components/form-fields/auto-complete-fields/AthleteAutoComplete';
import dayjs from 'dayjs';
import useCreateAthleteAppointment from 'hooks/athleteAppointment/useCreateAthleteAppointment';
import useUpdateAthleteAppointment from 'hooks/athleteAppointment/useUpdateAthleteAppointment';
import useCreateAthleteClass from 'hooks/athleteClass/useCreateAthleteClass';
import useUpdateAthleteClass from 'hooks/athleteClass/useUpdateAthleteClass';
import { AthleteAppointmentWrite, AthleteKiloClassWrite, Event } from 'types/generated';
import notificationHandler from 'utils/notifications';
import { v4 as uuidv4 } from 'uuid';

const useStyles = createStyles(theme => ({
  icon: {
    path: {
      fill: theme.colors.dark[2],
    },
  },
}));

interface EventCardProps {
  event: Event;
  athlete: AthleteAutoCompleteValue;
  clearAthlete: () => void;
}

const EventCard = ({ event, athlete, clearAthlete }: EventCardProps): JSX.Element => {
  const { classes, theme } = useStyles();

  const {
    eventType,
    eventName,
    eventId,
    startDate,
    startTime,
    duration,
    location,
    kiloClassCoachKiloClassCoachRoles,
    appointmentCoachAppointmentCoachRoles,
    capacity,
    athleteKiloClasses,
    athleteAppointments,
  } = event;

  const createAthleteClassMutation = useCreateAthleteClass();
  const updateAthleteClassMutation = useUpdateAthleteClass();
  const createAthleteAppointmentMutation = useCreateAthleteAppointment();
  const updateAthleteAppointmentMutation = useUpdateAthleteAppointment();

  const fullStartDate = dayjs(`${startDate}T${startTime}`);
  const formattedStartTime = fullStartDate.format('h:mmA');
  const formattedEndTime = dayjs(fullStartDate).add(duration, 'minutes').format('h:mmA');

  const isAppointment = eventType === 'appointment_type';

  const selectedAthleteClass = athleteKiloClasses?.find(
    athleteKiloClass => athleteKiloClass.athlete.athleteId === athlete.athleteId,
  );
  const selectedAppointment = athleteAppointments?.find(
    athleteKiloClass => athleteKiloClass.athlete.athleteId === athlete.athleteId,
  );

  const isWaitlisted = isAppointment ? false : !!selectedAthleteClass?.isWaitlisted;
  const isReserved = isAppointment
    ? !!selectedAppointment?.isReserved && !selectedAppointment?.isCheckedIn
    : !!selectedAthleteClass?.isReserved && !selectedAthleteClass?.isCheckedIn;

  const isCheckedIn = !!selectedAthleteClass?.isCheckedIn || !!selectedAppointment?.isCheckedIn;
  const isCheckinButtonLoading =
    createAthleteClassMutation.isLoading ||
    updateAthleteClassMutation.isLoading ||
    createAthleteAppointmentMutation.isLoading ||
    updateAthleteAppointmentMutation.isLoading;

  const athletes = isAppointment
    ? athleteAppointments
        ?.filter(appointment => appointment.isReserved || appointment.isCheckedIn)
        .map(athleteKiloClass => athleteKiloClass.athlete)
    : athleteKiloClasses
        ?.filter(kiloClass => kiloClass.isReserved || kiloClass.isCheckedIn)
        .map(athleteKiloClass => athleteKiloClass.athlete);
  const reservedAthletesCount = athletes?.length || 0;

  const isClassAtCapacity = capacity ? reservedAthletesCount >= capacity : false;

  const background = useMemo(() => {
    if (isCheckedIn) {
      return theme.fn.rgba(theme.colors.green[5], 0.1);
    }
    if (isReserved) {
      return theme.fn.rgba(theme.colors.kiloBlue[5], 0.1);
    }
    if (isWaitlisted) {
      return theme.fn.rgba(theme.colors.kiloBlue[5], 0.1);
    }
    return theme.white;
  }, [
    isCheckedIn,
    isReserved,
    isWaitlisted,
    theme.colors.green,
    theme.colors.kiloBlue,
    theme.fn,
    theme.white,
  ]);

  const { successNotification, errorNotification } = notificationHandler();

  const handleCheckInAthleteClass = (): void => {
    const payload: AthleteKiloClassWrite = {
      athleteKiloClassId: !!selectedAthleteClass ? selectedAthleteClass.athleteKiloClassId : uuidv4(),
      isReserved: !!selectedAthleteClass?.isReserved,
      isCheckedIn: true,
      isWaitlisted: false,
      kiloClass: eventId,
      athlete: athlete.athleteId,
      isLateCancel: false,
      isNoShow: false,
      chargeNow: null,
      chargeDate: null,
      chargeFee: null,
      taxRate: null,
      deductCredit: null,
      registrationDatetime: !!selectedAthleteClass
        ? selectedAthleteClass.registrationDatetime
        : dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
    };

    if (!!selectedAthleteClass) {
      updateAthleteClassMutation.mutate(payload, {
        onSuccess: () => {
          clearAthlete();
          successNotification(
            'Adding athlete to class success',
            `${athlete.label} has been successfully added to ${eventName}.`,
          );
        },
        onError: error => {
          errorNotification('Adding athlete failed', error.response?.data.message);
        },
      });
    } else {
      createAthleteClassMutation.mutate(payload, {
        onSuccess: () => {
          clearAthlete();
          successNotification(
            'Adding athlete to class success',
            `${athlete.label} has been successfully added to ${eventName}.`,
          );
        },
        onError: error => {
          errorNotification('Adding athlete failed', error.response?.data.message);
        },
      });
    }
  };

  const handleCheckInAthleteAppointment = (): void => {
    const payload: AthleteAppointmentWrite = {
      athleteAppointmentId: !!selectedAppointment ? selectedAppointment.athleteAppointmentId : uuidv4(),
      isReserved: !!selectedAppointment?.isReserved,
      isCheckedIn: true,
      appointment: eventId,
      athlete: athlete.athleteId,
      isLateCancel: false,
      isNoShow: false,
      chargeNow: null,
      chargeDate: null,
      chargeFee: null,
      taxRate: null,
      deductCredit: null,
    };
    if (!!selectedAppointment) {
      updateAthleteAppointmentMutation.mutate(payload, {
        onSuccess: () => {
          clearAthlete();
          successNotification(
            'Creating appointment for athlete success',
            `${athlete.label} has been successfully added to ${eventName}.`,
          );
        },
        onError: error => {
          errorNotification('Adding athlete failed', error.response?.data.message);
        },
      });
    } else {
      createAthleteAppointmentMutation.mutate(payload, {
        onSuccess: () => {
          clearAthlete();
          successNotification(
            'Creating appointment for athlete success',
            `${athlete.label} has been successfully added to ${eventName}.`,
          );
        },
        onError: error => {
          errorNotification('Adding athlete failed', error.response?.data.message);
        },
      });
    }
  };

  const handleCheckin = (): void => {
    if (!isCheckedIn) {
      if (isAppointment) {
        handleCheckInAthleteAppointment();
      } else {
        handleCheckInAthleteClass();
      }
    }
  };

  return (
    <Paper withBorder p='md' radius='md' bg={background} data-cy='eventCard'>
      <Flex wrap='nowrap' gap='md' align='center'>
        <Flex direction='column' justify='center' align='center' w='100%' maw={200} gap='xs'>
          <Title order={2} lineClamp={1}>
            {eventName}
          </Title>
          <Text align='center' size='xl' color='dark.4' weight={600}>
            {`${formattedStartTime} - ${formattedEndTime}`}
          </Text>
          {isCheckedIn && (
            <Badge color='green' variant='filled' size='xl' data-cy='reservationStatus'>
              Checked-in
            </Badge>
          )}
          {isReserved && (
            <Badge color='kiloBlue' variant='filled' size='xl' data-cy='reservationStatus'>
              Reserved
            </Badge>
          )}
          {isWaitlisted && (
            <Badge color='kiloBlue' variant='filled' size='xl' data-cy='reservationStatus'>
              In waitlist
            </Badge>
          )}
        </Flex>

        <Divider orientation='vertical' my='-xs' mr='xs' />
        <Group spacing='xs' noWrap align='top'>
          <Stack spacing='xs'>
            <Group spacing='xs' noWrap align='top'>
              <IconClockFilled size={theme.fontSizes.xl} className={classes.icon} />
              <Text color='dark.5' size='xl'>
                {`${duration} mins`}
              </Text>
            </Group>
            <Group spacing='xs' noWrap align='top'>
              <IconUser size={theme.fontSizes.xl} className={classes.icon} color={theme.colors.dark[2]} />
              {kiloClassCoachKiloClassCoachRoles.length && (
                <Stack sx={{ gap: 0 }}>
                  {kiloClassCoachKiloClassCoachRoles.map((ccccr, i) => (
                    <Text color={'dark.5'} size='xl' key={i}>
                      {ccccr.coach.fullName} ({ccccr.kiloClassCoachRole.name})
                    </Text>
                  ))}
                </Stack>
              )}
              {appointmentCoachAppointmentCoachRoles.length && (
                <Stack sx={{ gap: 0 }}>
                  {appointmentCoachAppointmentCoachRoles.map((acacr, i) => (
                    <Text color={'dark.5'} size='xl' key={i}>
                      {acacr.coach.fullName} ({acacr.appointmentCoachRole.name})
                    </Text>
                  ))}
                </Stack>
              )}

              {!appointmentCoachAppointmentCoachRoles.length &&
                !kiloClassCoachKiloClassCoachRoles.length && (
                  <Text color={'dimmed'} size='xl'>
                    -
                  </Text>
                )}
            </Group>
          </Stack>

          <Stack spacing='xs'>
            <Group spacing='xs' noWrap>
              <IconMapPinFilled size={theme.fontSizes.xl} className={classes.icon} />
              <Text color={location ? 'dark.5' : 'dimmed'} size='xl'>
                {location || '-'}
              </Text>
            </Group>
            <Group spacing='xs' noWrap>
              <IconUsers size={theme.fontSizes.xl} className={classes.icon} color={theme.colors.dark[2]} />
              <Text color='dark.5' size='xl'>
                {`${reservedAthletesCount} ${capacity ? `/ ${capacity} ` : ' '}`}
                {isClassAtCapacity && (
                  <Anchor sx={{ cursor: 'default' }} underline={false} color='red'>
                    (Class is at capacity)
                  </Anchor>
                )}
              </Text>
            </Group>
          </Stack>
        </Group>

        <Group ml='auto' position='right' spacing='xl' data-cy='actionButtonWrapper'>
          <Button
            color='gray'
            variant='default'
            size='xl'
            onClick={handleCheckin}
            loading={isCheckinButtonLoading}
            disabled={isCheckedIn}
          >
            Check in
          </Button>
        </Group>
      </Flex>
    </Paper>
  );
};

export default EventCard;
