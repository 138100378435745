import React from 'react';

import { LoadingOverlay, Avatar as MantineAvatar, createStyles } from '@mantine/core';
import { DropzoneProps, FileRejection, FileWithPath } from '@mantine/dropzone';
import { useHover } from '@mantine/hooks';
import { IconBarbell, IconPencil } from '@tabler/icons-react';

import FileUpload from './FileUpload';

const useStyles = createStyles(theme => ({
  avatarContainer: {
    position: 'relative',
    borderRadius: '100%',
    overflow: 'hidden',
  },
  pencilIconContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.fn.rgba(theme.colors.dark[4], 0.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface IAvatarProps {
  imgSrc: string;
  handleFileChange: (arg: FileWithPath[]) => void;
  handleFileReject?: (files: FileRejection[]) => void;
  loading?: boolean;
  disabled?: boolean;
  size?: number;
  children?: DropzoneProps['children'];
  useBarbell?: boolean;
}

const Avatar = ({
  handleFileChange,
  imgSrc,
  handleFileReject,
  disabled = false,
  size = 175,
  loading = false,
  useBarbell = false,
}: IAvatarProps): JSX.Element => {
  const { classes, theme } = useStyles();
  const { hovered, ref } = useHover();

  return (
    <div ref={ref}>
      <FileUpload
        size={size - size / 8} // For some reason the avatar component is actually this size...
        onDrop={handleFileChange}
        disabled={loading}
        handleFileReject={handleFileReject}
        sx={{
          border: 0,
          borderRadius: '100%',
        }}
        padding={0}
      >
        <div className={classes.avatarContainer}>
          <LoadingOverlay visible={loading} />
          {useBarbell ? (
            <MantineAvatar src={imgSrc} size={size} variant='outline' color='kiloBlue' radius='100%'>
              <IconBarbell size={'100%'} radius='100%' />
            </MantineAvatar>
          ) : (
            <MantineAvatar src={imgSrc} variant='outline' color='kiloBlue' radius='100%' size={size} />
          )}

          {hovered && !loading && !disabled && (
            <div className={classes.pencilIconContainer}>
              <IconPencil size={50} color={theme.white} />
            </div>
          )}
        </div>
      </FileUpload>
    </div>
  );
};

export default Avatar;
