import React, { useContext, createContext, useState, useCallback, useEffect, useMemo } from 'react';

import useClearProfile from 'hooks/auth/useClearProfile';
import useCsrf from 'hooks/auth/useCsrf';
import useImpersonate from 'hooks/auth/useImpersonate';
import useLogin from 'hooks/auth/useLogin';
import useLogout from 'hooks/auth/useLogout';
import useSelectProfile from 'hooks/auth/useSelectProfile';
import useSession from 'hooks/auth/useSession';
import useStopImpersonation from 'hooks/auth/useStopImpersonation';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Login, Session } from 'types';
import notificationHandler from 'utils/notifications';

interface ProviderProps {
  session: Session | null;
  isLoading: boolean;
  refreshMe: () => void;
  login: (payload: Login) => void;
  logout: () => void;
  selectProfile: (profileId: string) => void;
  clearProfile: () => void;
  impersonate: (profileId: string) => void;
  stopImpersonation: () => void;
  hasPerm: (permission: string) => boolean;
}

const AuthContext = createContext<ProviderProps>({
  session: null,
  isLoading: false,
  refreshMe: () => {},
  login: () => {},
  logout: () => {},
  selectProfile: () => {},
  clearProfile: () => {},
  impersonate: () => {},
  stopImpersonation: () => {},
  hasPerm: () => false,
});

const AuthProvider = ({ children }: { children: React.ReactNode }): any => {
  const queryClient = useQueryClient();
  const { errorNotification } = notificationHandler();
  const loginMutation = useLogin();
  const logoutMutation = useLogout();
  const selectProfileMutation = useSelectProfile();
  const clearProfileMutation = useClearProfile();
  const impersonationMutation = useImpersonate();
  const stopImpersonationMutation = useStopImpersonation();
  const navigate = useNavigate();

  const [session, setSession] = useState<Session | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { data: sessionData, isSuccess, isError, refetch: refreshMe } = useSession();
  useCsrf();

  const login = useCallback(
    ({ email, password }: Login & { navigateAfterLogin?: boolean }): void => {
      setIsLoading(true);
      loginMutation.mutate(
        { email, password },
        {
          onSuccess: () => {
            refreshMe();
          },
          onError: error => {
            errorNotification('Login failed', error.response?.data.message);
            refreshMe();
          },
        },
      );
    },
    [errorNotification, loginMutation, refreshMe],
  );

  const logout = useCallback((): void => {
    logoutMutation.mutate(null, {
      onSuccess: () => {
        queryClient.clear();
        setSession(null);
        navigate('/login');
      },
      onError: () => {
        errorNotification('Logout failed');
        refreshMe();
      },
    });
  }, [errorNotification, navigate, queryClient, refreshMe, logoutMutation]);

  const selectProfile = useCallback(
    (profileId: string): void => {
      selectProfileMutation.mutate(
        { profileId },
        {
          onError: res => {
            errorNotification('Profile selection failed', res.response?.data.message);
          },
          onSettled: () => {
            refreshMe();
          },
        },
      );
    },
    [errorNotification, refreshMe, selectProfileMutation],
  );

  const clearProfile = useCallback((): void => {
    clearProfileMutation.mutate(null, {
      onSuccess: () => {
        queryClient.clear();
      },
      onError: () => {
        errorNotification('Something failed');
      },
      onSettled: () => {
        refreshMe();
      },
    });
  }, [clearProfileMutation, errorNotification, queryClient, refreshMe]);

  const impersonate = useCallback(
    (profileId: string): void => {
      impersonationMutation.mutate(
        { profileId },
        {
          onSuccess: () => {
            queryClient.clear();
          },
          onError: res => {
            errorNotification('Impersonation failed', res.response?.data.message);
          },
          onSettled: () => {
            refreshMe();
          },
        },
      );
    },
    [errorNotification, impersonationMutation, queryClient, refreshMe],
  );

  const stopImpersonation = useCallback((): void => {
    stopImpersonationMutation.mutate(null, {
      onSuccess: () => {
        queryClient.clear();
      },
      onError: () => {
        errorNotification('Something failed');
      },
      onSettled: () => {
        refreshMe();
      },
    });
  }, [errorNotification, queryClient, refreshMe, stopImpersonationMutation]);

  const hasPerm = useCallback(
    (permission: string): boolean => {
      if (!session) return false;
      if (session.athlete) return true;
      if (session.staff) {
        if (session.role === 'owner') return true;
        return session.staff.permissions.includes(permission);
      }
      if (session.kiloStaff) return true;
      return false;
    },
    [session],
  );

  const contextValue = useMemo(
    () => ({
      session,
      isLoading,
      login,
      logout,
      refreshMe,
      hasPerm,
      selectProfile,
      clearProfile,
      impersonate,
      stopImpersonation,
    }),
    [
      session,
      isLoading,
      login,
      logout,
      refreshMe,
      hasPerm,
      selectProfile,
      clearProfile,
      impersonate,
      stopImpersonation,
    ],
  );

  useEffect(() => {
    if (isSuccess && sessionData?.data) {
      setSession(sessionData.data);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, sessionData?.data]);

  useEffect(() => {
    if (isError) {
      setSession(null);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = (): ProviderProps => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export default AuthProvider;
