import React, { useEffect, useState } from 'react';

import {
  Alert,
  Button,
  Container,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconExclamationCircle } from '@tabler/icons-react';
import { ReactComponent as KiloLogo } from 'assets/svg/long_kilo_blue_white.svg';
import { useAuth } from 'context/auth-context';
import useProfiles from 'hooks/profile/useProfiles';
import useUpdateDefaultProfile from 'hooks/profile/useUpdateDefaultProfile';
import { ProfileRetrieve } from 'types';

import EditLoginModal from './components/EditLoginModal';
import ProfileCard from './components/ProfileCard';

const ProfileManagement = (): JSX.Element => {
  const theme = useMantineTheme();

  const { session, logout } = useAuth();
  const [profiles, setProfiles] = useState<ProfileRetrieve[]>([]);
  const [isEditLoginModalOpen, editLoginModalHandlers] = useDisclosure(false);

  const updateDefaultProfileMutation = useUpdateDefaultProfile();

  const { data, isSuccess, isLoading, isError } = useProfiles({
    user: session?.user.userId,
    includeLinked: true,
  });

  useEffect(() => {
    if (isSuccess && !isLoading && data) {
      setProfiles(data.pages.map(page => page.data.results).flat());
    }
  }, [isLoading, data, setProfiles, isSuccess]);

  return (
    <Container size='xs' py='xl'>
      <KiloLogo width='100%' height={60} />
      <Title align='center' mt='xl' sx={{ fontWeight: 900 }}>
        {`Select a profile`}
      </Title>
      <Stack mt='md'>
        <LoadingOverlay visible={isLoading} />
        {isError && (
          <Alert icon={<IconExclamationCircle size='md' />} title='Error' color='red'>
            Something went wrong while fetching data!
          </Alert>
        )}
        {isSuccess && profiles.length === 0 && (
          <Text ta='center' size='lg'>
            You no longer have an active profile with Kilo
          </Text>
        )}
        {isSuccess &&
          profiles.length > 0 &&
          profiles.map(profile => (
            <ProfileCard
              key={profile.profileId}
              profile={profile}
              updateDefaultProfileMutation={updateDefaultProfileMutation}
            />
          ))}
        <Group grow>
          <Button onClick={editLoginModalHandlers.open} variant='light' sx={{ boxShadow: theme.shadows.md }}>
            Edit Login
          </Button>
          <Button onClick={logout} color='red' variant='light' sx={{ boxShadow: theme.shadows.md }}>
            Logout
          </Button>
        </Group>
      </Stack>
      <EditLoginModal opened={isEditLoginModalOpen} closeHandler={editLoginModalHandlers.close} />
    </Container>
  );
};

export default ProfileManagement;
