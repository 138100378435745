import React, { useState } from 'react';

import { AppShell, createStyles } from '@mantine/core';
import BannerModal from 'components/banner/BannerModal';
import { use100vh } from 'react-div-100vh';
import { Outlet } from 'react-router-dom';
import { INavLink } from 'routes/types';

import Header from './components/Header';
import Navbar from './components/Navbar';

const useStyles = createStyles(() => ({
  content: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
}));

type DesktopLayoutProps = {
  navLinks: INavLink[];
};

const DesktopLayout = ({ navLinks }: DesktopLayoutProps): JSX.Element => {
  const { classes } = useStyles();
  const [headerOpened, setHeaderOpened] = useState(false);
  const height = use100vh();

  return (
    <AppShell
      navbarOffsetBreakpoint='sm'
      padding={0}
      h={height || undefined}
      fixed
      header={<Header opened={headerOpened} handleBurgerClick={() => setHeaderOpened(o => !o)} />}
      navbar={<Navbar opened={headerOpened} links={navLinks} />}
    >
      <div className={classes.content}>
        <Outlet />
      </div>
      <BannerModal />
    </AppShell>
  );
};

export default DesktopLayout;
