import React, { useState } from 'react';

import { Button, Group, LoadingOverlay, Paper, Stack, Text, TextInput, Title, Box } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useShallowEffect } from '@mantine/hooks';
import HelpButton from 'components/HelpButton';
import useSelfKiloStaff from 'hooks/kiloStaff/useSelfKiloStaff';
import useUpdateSelfKiloStaff from 'hooks/kiloStaff/useUpdateSelfKiloStaff';
import { emailValidator, nameValidator } from 'utils/form-validators';
import notificationHandler from 'utils/notifications';

interface KiloStaffAccountFormValues {
  kiloStaffId: string;
  email: string;
  firstName: string;
  lastName: string;
}

const KiloStaffAccountForm = (): JSX.Element => {
  const [isEditable, setIsEditable] = useState(false);

  const { successNotification, errorNotification } = notificationHandler();
  const updateMutation = useUpdateSelfKiloStaff();

  const { data, isLoading, isSuccess } = useSelfKiloStaff();

  const form = useForm<KiloStaffAccountFormValues>({
    initialValues: {
      kiloStaffId: data?.data.kiloStaffId || '',
      email: data?.data.email || '',
      firstName: data?.data.firstName || '',
      lastName: data?.data.lastName || '',
    },

    validate: {
      firstName: value => nameValidator(value),
      lastName: value => nameValidator(value),
      email: value => emailValidator(value),
    },
  });

  const handleFormReset = (): void => {
    setIsEditable(false);
  };

  const handleSubmit = (formValues: KiloStaffAccountFormValues): void => {
    if (isSuccess && data?.data) {
      updateMutation.mutate(
        {
          ...data.data,
          ...formValues,
        },
        {
          onSuccess: () => {
            successNotification('Account details updated successfully');
            handleFormReset();
          },
          onError: error => {
            errorNotification('Account details update failed', error.response?.data.message);
          },
        },
      );
    }
  };

  useShallowEffect(() => {
    if (isSuccess && data?.data) {
      form.setValues({
        ...data.data,
      });
    }
  }, [data?.data]);

  return (
    <Paper radius='lg' p='md' shadow='md' withBorder>
      <Group position='apart' pb='xs'>
        <Title order={4}>General Information</Title>
        <Group position='right'>
          {isEditable ? (
            <>
              <Button
                variant='light'
                compact
                color='red'
                onClick={handleFormReset}
                disabled={updateMutation.isLoading}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                form='staff-account-form'
                compact
                color='green'
                loading={updateMutation.isLoading}
              >
                Save
              </Button>
            </>
          ) : (
            <Button compact onClick={() => setIsEditable(true)}>
              Edit
            </Button>
          )}
        </Group>
      </Group>
      <form id='staff-account-form' onSubmit={form.onSubmit(handleSubmit)}>
        <LoadingOverlay visible={isLoading} />
        <Stack spacing='xs' p='xs'>
          <Group grow spacing='xs'>
            <TextInput
              {...form.getInputProps('firstName')}
              label='First Name'
              placeholder='Enter First Name'
              required={isEditable}
              disabled={!isEditable}
            />
            <TextInput
              {...form.getInputProps('lastName')}
              label='Last Name'
              placeholder='Enter Last Name'
              required={isEditable}
              disabled={!isEditable}
            />
          </Group>
          <Group>
            <TextInput
              {...form.getInputProps('email')}
              type='email'
              label='Email / Login'
              placeholder='Enter Email'
              disabled
              sx={{ flexGrow: 1 }}
            />
            {isEditable && (
              <Box pt='20px'>
                <HelpButton width={'md'} position='top-start' withinPortal>
                  <Text size='sm' sx={{ whiteSpace: 'normal' }}>
                    {`To change emails use the `}
                    <Text fw={600} span>
                      Edit Login
                    </Text>
                    {` button in your profile selection page.`}
                  </Text>
                </HelpButton>
              </Box>
            )}
          </Group>
        </Stack>
      </form>
    </Paper>
  );
};

export default KiloStaffAccountForm;
